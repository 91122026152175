










































import {
  defineComponent, useContext,
} from '@nuxtjs/composition-api';

import Slider from "~/components/Palmers/BaseComponents/Slider/Slider.vue";
import SliderItem from "~/components/Palmers/BaseComponents/Slider/SliderItem.vue";
import VideoBlock from "~/components/Palmers/BaseComponents/VideoBlock.vue";
import {useUrl} from "~/composables/Palmers";

export default defineComponent({
  name: 'HomeSlider',
  components: {
    Slider,
    SliderItem,
    VideoBlock
  },
  props: {
    videosData: {
      type: Array,
      require: true
    }
  },
  setup() {
    const {getPageUrl} = useUrl();
    const {$config} = useContext();

    return {
      getPageUrl,
      magentoMediaUrl: $config.magentoMediaUrl as string,
    };
  },
  methods: {
    goPrev() {
      //@ts-ignore
      this.$refs['home-slider']?.swiper?.slidePrev();
    },
    goNext() {
      //@ts-ignore
      this.$refs['home-slider']?.swiper?.slideNext();
    },
    getUrl(preview) {
      if((preview as string).indexOf('http') > -1) {
        return preview;
      }

      let url = this.magentoMediaUrl;

      if(url[url.length - 1] === '/') {
        url = url.slice(0, -1);
      }

      return `${url}/${preview}`;
    },
    isExternal(url) {
      return url.indexOf('http') > -1;
    }
  }
});
