



















import {
  defineComponent,
} from '@nuxtjs/composition-api';

import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';

export default defineComponent({
  name: 'Slider',
  props: {
    updatedSliderOptions: {
      type: Object,
      require: true,
      default() {
        return {
          type: "carousel",
          autoplay: false,
          rewind: false,
          gap: 0
        };
      },
    },
    title: {
      type: String,
      require: false,
    },
    arrows: {
      type: Boolean,
      require: true,
      default() {
        return true;
      },
    },
    sliderIndex: {
      type: Number,
      require: true,
      default() {
        return 0;
      },
    },
    center: {
      type: Boolean,
      default: null,
    },
    arrowParent: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    swiper: {}
  }),
  async mounted() {
    await this.$nextTick();
    const options = {
      ...this.updatedSliderOptions,
      watchSlidesProgress: true
    };
    if (this.arrows) {
      options['navigation'] = {
        nextEl: `${this.arrowParent ? `.${this.arrowParent} `: ''}.glide__arrow--right`,
        prevEl: `${this.arrowParent ? `.${this.arrowParent} `: ''}.glide__arrow--left`,
      }
    }

    this.swiper = new Swiper(this.$refs.swiper, options);
  },
  methods: {
    goToPrevSlide() {
      //@ts-ignore
      this.swiper.slidePrev();
    },
    goToNextSlide() {
      //@ts-ignore
      this.swiper.slideNext();
    }
  }
});
